/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var allProducts = "";
  var Sage = {
    // All pages
    'common': {
      init: function() {

        function autoHeightAnimate(element, time){ 
            var curHeight = element.height(), // Get Default Height
                autoHeight = element.css('height', 'auto').height(); // Get Auto Height
                element.height(curHeight); // Reset to Default Height
                element.stop().animate({ height: autoHeight }, parseInt(time)); // Animate to Auto Height
        }

        $(window).load(function() {
          // Animate loader off screen
          $("#wrapper-spinner").fadeOut("slow");
        });


        //menu
        // if(windowWidth >= 768) {
          $('.open-sub-menu, .menu-item-has-children').mouseover(function(){
            // $('.sub-list').height(0);
            // $(this).find('.sub-list').css('height', 'auto');
            autoHeightAnimate($(this).find('.sub-list, .sub-menu'), 200);
          });
          $('.open-sub-menu, .menu-item-has-children').mouseleave(function(){
            $('.sub-list, .sub-menu').stop().animate({ height: '0' }, 200);
          });
        // }

        // JavaScript to be fired on all pages
        $(window).resize(function() {
            var windowHeight = $(window).height();
            var windowWidth = $(window).width();

            if(windowWidth >= 768) {
              windowHeight = $(window).height();
              windowWidth = $(window).width();

              $('.fit-screen').height(windowHeight);
            } 

            if(windowWidth < 768) {
              $('.fit-screen').css('height','500px');
            }


        });

        $(window).trigger('resize');

        $('.big-banner').parallax('50%','0.2');

        //menu mobile 
        $('.menu-icon').click(function(){
          $('#header').fadeIn(200);
        });
        $('.menu-close').click(function(){
          $('#header').fadeOut(200);
        });

        var wow = new WOW(
          {
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       0,          // distance to the element when triggering the animation (default is 0)
            mobile:       true,       // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
            callback:     function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          }
        );
        wow.init();
        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // Remplir le champ titre de la bannière au load
        var firstTitle = $('.item.active').data('title');
        var firstLink = $('.item.active').data('link');
                
        if(firstLink === ""){
          $('.wrapper-box-title h1').html(firstTitle);           
        }else{
          $('.wrapper-box-title h1').html("<a href='"+firstLink+"'>"+firstTitle+"</a>" );           
        }        

        //changer dynamiquement le titre d ela bannière
        $('#carousel-home').on('slide.bs.carousel', function (e) {
          $('.wrapper-box-title').fadeOut(500);
        });

        //changer dynamiquement le titre d ela bannière
        $('#carousel-home').on('slid.bs.carousel', function (e) {
          var title = $(e.relatedTarget).data('title');
          var link = $(e.relatedTarget).data('link');
          
          $('.wrapper-box-title').fadeIn(500);
          if(link === ""){
            $('.wrapper-box-title h1').html(title);           
          }else{
            $('.wrapper-box-title h1').html("<a href='"+link+"'>"+title+"</a>" );           
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // COLLECTIONS page
    'page_template_page_collections': {
      init: function() {
        var windowHeight = $(window).height();
        var windowWidth = $(window).width();

        if(windowWidth >= 768) {
          $("img").one("load", function() {
            $('.box-text').height($('.box-img img').height());
          }).each(function() {
            if(this.complete) {
              $(this).load();
            } 
          });
        }

        $(window).resize(function() {
          if(windowWidth >= 768) {
            windowHeight = $(window).height();
            windowWidth = $(window).width();
            $('.box-text').height($('.box-img img').height());
          }
          if(windowWidth < 768) {
            windowHeight = $(window).height();
            windowWidth = $(window).width();
            $('.box-text').css('height', 'auto');
          }
        });
        

        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // BUSINESS page
    'page_template_page_business': {
      init: function() {
        var windowHeight = $(window).height();
        var windowWidth = $(window).width();

        if(windowWidth >= 992) {
            $('.wrapper-boxes').each(function(e){
                var textHeight = $(this).find('.box-text').height();
                $(this).find('.box-img').height(textHeight);
            });
        }

        $(window).resize(function() {
          windowHeight = $(window).height();
          windowWidth = $(window).width();
          
          if(windowWidth >= 992) {
            $('.wrapper-boxes').each(function(e){
                var textHeight = $(this).find('.box-text').height();
                $(this).find('.box-img').height(textHeight);
            });
          }
          
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // BUSINESS page
    'page_template_page_professional_leed': {
      init: function() {
          var windowHeight = $(window).height();
          var windowWidth = $(window).width();
          var heights = $(".box").map(function () { return $(this).height(); }).get();
          var maxHeight = Math.max.apply(null, heights);

//          if(windowWidth >= 768) {
//            //$('.box').height(maxHeight);
//          }
//
//          $(window).resize(function() {
//            return; console.log("de");
//            windowHeight = $(window).height();
//            windowWidth = $(window).width();
//            
//            if(windowWidth >= 768) {
//              $('.box').css('height', 'auto');
//              heights = $(".box").map(function () { return $(this).height(); }).get();
//              maxHeight = Math.max.apply(null, heights);
//
//              $('.box').height(maxHeight);
//            }
//
//            if(windowWidth < 768) {
//              $('.box').css('height', 'auto');
//            }
//            
//          }).trigger("resize");
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // FAQ page
    'page_template_page_faq': {
      init: function() {
        $('.question-box').click(function() {
          $(this).find('.answer').slideToggle('fast');
          $(this).find('.glyphicon').toggleClass('open');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // FAQ page
    'page_template_page_retailers': {
      init: function() {
        $('.question-box p.question').click(function() {
          $(this).parent().find('.answer').slideToggle('fast');
          $(this).parent().find('.glyphicon').toggleClass('open');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },    
    'page_template_page_products':{
      init: function(){
        allProducts = $('#produits-list-container .one-produit');
        
        $('#modal-produit').on('show.bs.modal', function (e) {
         // var info  = ($(e.relatedTarget).data("info"));
          var id  = ($(e.relatedTarget).data("id"));
          var info = jsonProdInfo[id];
          var photoPopup = (info.photopopup !== "") ? info.photopopup : info.photo;          
          
          $("#modal-txt-title").html(info.title);
          $("#modal-txt-couleur").html(info.couleur);
          $("#modal-txt-essence").html(info.essence);
          $("#modal-txt-photo").attr("src", photoPopup);
          $("#modal-txt-logo").attr("src", info.logo);
          $("#modal-collection-url").attr("href", info.urlcollection);
          var indexGrille = ["grade", "luster", "size"];
          for(i=0; i<indexGrille.length; i++){
            $("#" + indexGrille[i] + "_solid").html(info[indexGrille[i] + "_solid"]);
            $("#" + indexGrille[i] + "_engineer").html(info[indexGrille[i] + "_engineer"]);
            $("#" + indexGrille[i] + "_lock").html(info[indexGrille[i] + "_lock"]); 
          }
          

          // HIDE VERTICAL EMPTY LINE
          //Check si info de lustre
          if(info["luster_solid"] === "" && info["luster_engineer"] === "" && info["luster_lock"] === ""){
            $(".tdLustre").hide();
          }else {
            $(".tdLustre").show();            
          }    
          //Check si info de grade
          if(info["grade_solid"] === "" && info["grade_engineer"] === "" && info["grade_lock"] === ""){
            $(".tdGrade").hide();
          }else {
            $(".tdGrade").show();            
          }  
          //Check si info de largeur
          if(info["size_solid"] === "" && info["size_engineer"] === "" && info["size_lock"] === ""){
            $(".tdLargeur").hide();
          }else {
            $(".tdLargeur").show();            
          }            

          
          // HIDE HORIZONTALE EMPTY LINE                   
          //Check Colonne 1
          if(info["grade_solid"] === "" && info["size_solid"] === "" && info["luster_solid"] === "" && info["pdf_content1"] === "" ){
            $("#trMassif").addClass("hide");
          }else {
            $("#trMassif").removeClass("hide");
          }      
          
          //Check Colonne 2
          if(info["grade_engineer"] === "" && info["size_engineer"] === "" && info["luster_engineer"] === "" && info["pdf_content2"] === "" ){
            $("#trEngineer").addClass("hide");
          }else {
            $("#trEngineer").removeClass("hide");
          }                
          
          //Check Colonne 3
          if(info["grade_lock"] === "" && info["size_lock"] === "" && info["luster_lock"] === "" && info["pdf_content3"] === "" ){
            $("#trLock").addClass("hide");
          }else {
            $("#trLock").removeClass("hide");
          }                            
          
          // SET DATA
          $("#col_title1").html( (info.col_title1 === "") ? $("#col_title1").data("default") : info.col_title1  );
          $("#col_title2").html( (info.col_title2 === "") ? $("#col_title2").data("default") : info.col_title2  );
          $("#col_title3").html( (info.col_title3 === "") ? $("#col_title3").data("default") : info.col_title3  );
          
          $("#lines_title1").html( (info.lines_title1 === "") ? $("#lines_title1").data("default") : info.lines_title1  );
          $("#lines_title2").html( (info.lines_title2 === "") ? $("#lines_title2").data("default") : info.lines_title2  );
          $("#lines_title3").html( (info.lines_title3 === "") ? $("#lines_title3").data("default") : info.lines_title3  );
          $("#lines_title4").html( (info.lines_title4 === "") ? $("#lines_title4").data("default") : info.lines_title4  );

          $("#pdf_content1").html( (info.pdf_content1 )   );
          $("#pdf_content2").html( (info.pdf_content2 )   );
          $("#pdf_content3").html( (info.pdf_content3 )   );
          if(info["pdf_content1"] === "" && info["pdf_content2"] === "" && info["pdf_content3"] === ""){
            $("#trPdfs").hide();
          }else {
            $("#trPdfs").show();            
          }    



          $("#pdf4").attr("href", info.pdf4).show();
          if(info.pdf4 === "" || info.pdf4 === "false" ||  info.pdf4 === false){
            $("#pdf4").hide();            
          }
        });     
        
        
        //GESTION DES TRIS
        $("#filtre-container li span").click(function(e){ 
          var filter = $(this).data("filter");
          
          if($(this).hasClass("active")){
           // $(this).removeClass("active");  
          }else{
            $("#filtre-container li span").removeClass("active");          
            $(this).addClass("active");            
          }          
          console.log(filter);
          if(filter === "collections"){
            
            sortingProduct(collectionJson, "collection");         

          } else if(filter === "species"){
            
            sortingProduct(essencesJson, "essence");         
            
          } else if(filter === "colors"){
            
            sortingProduct(colorsJson, "couleur");

          }
        });
        $("#filtre-container li span:eq(1)").click();

        //Multi Select style
        $('select.multiselect').multipleSelect({selectAll:false});
        
        $("#selectCouleur, #selectCollection, #selectEssence").change(function(){
          filterProduct();
        });
  
  
        function hideFilterTitle(){
          $(".filter-title").each(function(index){
            var next = $(this).next();
            var allHide = true;
            var currentTitle = $(this);
            
            next.find(".one-produit").each(function(){
              if($(this).css("display") == "block"){
                allHide = false; 
              }
            });
            
            if(allHide){
              currentTitle.hide();
            }else{
              currentTitle.show();
              
            }
          });
        } 
  
        function sortingProduct(elemArray, dataName){
            $('#produits-list-container').html("");    
            for(i=0; i<elemArray.length; i++){
              var titre = elemArray[i][1];
              var id = parseInt(elemArray[i][0]);

            var img = (elemArray[i][2]);
              var styleBg = "";
              if(img){
                styleBg = " style='background-image:url("+img+")' ";
              }
              
              $('#produits-list-container').append("<p class='filter-title' "+styleBg+" >"+ titre+"</p>");    
              var $div = $("<div>", { "class": "container-prod-filter row"});
 
              allProducts.each(function(index){
  
                if(parseInt($(this).data(dataName)) === id){
                  //$('#produits-list-container').append($(this));                                    
                 $div.append($(this));                                    
                }
              });
              $('#produits-list-container').append($div);                                    
              
            }    
          
            hideFilterTitle();      
        }
        
  
        function filterProduct(){
          $("#produits-list .one-produit").each(function(e){
            var selectedCollection = ($("#selectCollection").val());
            var selectedCouleur = ($("#selectCouleur").val());
            var selectedEssence = ($("#selectEssence").val());
            
            var collectionID = ($(this).data("collection")).toString();     
            var couleurID = ($(this).data("couleur")).toString();     
            var essenceID = ($(this).data("essence")).toString();   
           // $(".filter-title").remove();
           // $("#filtre-container li span").removeClass("active");             
            $(this).show();              
            
            if( selectedEssence !== null && selectedEssence.indexOf(essenceID) == -1){
              $(this).hide();              
            }
            if( selectedCollection !== null && selectedCollection.indexOf(collectionID) == -1){

              $(this).hide();              
            }
            if( selectedCouleur !== null && selectedCouleur.indexOf(couleurID) == -1){
              $(this).hide();              
            }

          });   
          hideFilterTitle();      
        }
        
      },
      finalize:function(){
        
      }
    },
    
    'page_template_page_products_moulure':{
      init: function(){
        $('#modal-produit').on('show.bs.modal', function (e) {
          var info  = ($(e.relatedTarget).data("info"));
          $("#modal-txt-title").html(info.title);
          $("#modal-txt-content").html(info.content);
          $("#modal-img-photo").attr("src", info.photo);
          $("#modal-img-schema").attr("src", info.schema);
          $("#pdf1").attr("href", info.pdf);

          
        });     
        
        
        //Gestion des filtres
        $("#filtre-container li span").click(function(e){
          
          if($(this).hasClass("active")){
            $(this).removeClass("active");  
            $("#produits-list .one-produit").show();            
          }else{
            $("#filtre-container li span").removeClass("active");          
            $(this).addClass("active");
            var selID = ($(this).data("id"));
            
            if(selID === "all"){
              $("#produits-list .one-produit").show();
            }else{
              $("#produits-list .one-produit").each(function(e){
                var curID = ($(this).data("type"));     
                $(this).hide();  
                if(curID === selID){
                  $(this).show();
                }
              });                
            }
            
          
          }
          

        });
        
      },
      finalize:function(){
        
      }
    },    
    
    
    'page_template_page_realisations' : {
      init:function(){
        
        allProductsOriginal = $('#grid .one-real');
        
        //GESTION DES TRIS
        $("#filtre-container li span").click(function(e){
          var filter = $(this).data("filter");

          if($(this).hasClass("active")){
           // $(this).removeClass("active");  
          }else{
            $("#filtre-container li span").removeClass("active");          
            $(this).addClass("active");            
          }          
          var allProducts = $.extend(true,{},allProductsOriginal);


          if(filter === "collections"){

            allProducts.sort(function(a, b){
                return a.dataset.collection - b.dataset.collection
            }); 
            
          } else if(filter === "species"){

            allProducts.sort(function(a, b){
              if(a.dataset.essence < b.dataset.essence ) return -1;
              if(a.dataset.essence > b.dataset.essence ) return 1;
              return 0;                
            });         

          } else if(filter === "colors"){

            allProducts.sort(function(a, b){
              if(a.dataset.couleur < b.dataset.couleur ) return -1;
              if(a.dataset.couleur > b.dataset.couleur ) return 1;
              return 0;                
            }); 
          }
         
         console.log(allProducts);
          $('#grid').html(allProducts);
          salvattore.init();          
          
        });        
        
        $('#modal-realisation').on('show.bs.modal', function (e) {

          var info  = ($(e.relatedTarget).data("info"));

          $("#modal-photo").attr("src", info.photo);
          $("#modal-logo").attr("src", info.logo);
          
          $("#modal-essence").html(info.essence).parent().show();
          if(info.essence === ""){
            $("#modal-essence").parent().hide();            
          }
          
          $("#modal-couleur").html(info.couleur).parent().show();
          if(info.couleur === ""){
            $("#modal-couleur").parent().hide();            
          }          
          
          $("#modal-grade").html(info.grade).parent().show();
          if(info.grade === ""){
            $("#modal-grade").parent().hide();            
          }              
          
          $("#modal-largeur").html(info.largeur).parent().show();  
          if(info.largeur === ""){
            $("#modal-largeur").parent().hide();            
          }         
          
          $("#modal-couleur").html(info.couleur).parent().show();
          if(info.couleur === ""){
            $("#modal-couleur").parent().hide();            
          }          
                    
          $("#modal-lustre").html(info.lustre).parent().show();
          if(info.lustre === ""){
            $("#modal-lustre").parent().hide();            
          }                    
          
          
          
                    
        });           
      }
    },
    'page_template_page_contact' : {
      init:function(){

        //Envoi de mail via ajax
        $("#formContact").submit(function(e){          
          e.preventDefault();
          $("#formContact .txtMessage, #formContact .txtError").hide();

          var error = 0;
          $("#formContact [data-required]").each(function(){
              $(this).removeClass("data-required");                    
              if($(this).val() === ""){
                  $(this).addClass("data-required");
                  error=1;
              }
          });

          //Si pas erreur,  on envoi
          if(error === 0){                    
              var sData = $(this).serialize();
              $.ajax({
                method: "POST",
                url: ajaxurl,
               // dataType : "json",
                data:  sData 
              })
              .done(function( data ) {
                  if(data === "sent"){                        
                    $("#txtFormGood").slideDown(300);
                    $("#formContact [data-required]").val("");
                  }                                         
              });                                          
          } else{
            $("#txtFormError").slideDown(300);
          }         
        });

      }
    },
    
    'page_template_page_maintenance' : {
      init:function(){
        //GESTION DES TRIS
        $cleaners = $(".one-cleaner");
        $("#filtre-container li span").click(function(e){
          var filter = $(this).data("filter");
          
          if($(this).hasClass("active")){
           // $(this).removeClass("active");  
          }else{
            $("#filtre-container li span").removeClass("active");          
            $(this).addClass("active");            
          } 
          
          $cleaners.hide();
          
          
          if(filter==="all"){
            $cleaners.show();
          }else{
            $cleaners.each(function(){
              var curtype = parseInt($(this).data("type"));
              filter = parseInt(filter);
              
              if(curtype === filter ){
                $(this).show();
              }
              
            });
          }
          

        });  

        // modal
        $('.modalvideobtn').click(function(){
          var urlyoutube = $(this).data('url');
          console.log('url' + urlyoutube);
          $('#modal-video iframe').attr('src',urlyoutube);
        });

        $('#modal-video').on('hidden.bs.modal', function () {
           $('#modal-video iframe').attr('src','');
        })   
      }
    }    
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
